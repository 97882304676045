import cssHasPseudo from "../../node_modules/css-has-pseudo/index.mjs";

import "./imports.js";
import "./modals.js";

cssHasPseudo(document);

let empLinks = document.getElementById("employee-links");
empLinks.addEventListener("change", (e) => {
  if (e.target.value !== "") {
    window.location = e.target.value;
  }
});
