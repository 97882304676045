const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

function openMenu(parent) {
  const button = $("button", parent);

  parent?.classList.add("open");
  button?.setAttribute(`aria-expanded`, `true`);
}

function closeMenu(parent) {
  const button = $("button", parent);

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

// Observes the site header, which should have `top: -1px`, and toggles
// the `stuck` class when it hits the top of the page.
new IntersectionObserver(
  ([e]) => e.target.classList.toggle("stuck", e.intersectionRatio < 1),
  { threshold: [1] }
).observe($(`header[role="banner"]`));

document.addEventListener("click", function(event) {
  const button = event.target.closest(`.platforma-nav-with-utility [aria-controls="menu"]`);
  if (!button) {
    return;
  }

  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].platforma-nav-with-utility");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body").classList.toggle("overflow-hidden");
});

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) nav > ul > .isParent");
  if (!closest) return;
  openMenu(closest);
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) nav > ul > .isParent");
  if (!closest) return;
  closeMenu(closest);
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility li > a + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    closeMenu(item);
  })

  if ($navItem.classList.contains("open")) {
    closeMenu($navItem);
  } else {
    openMenu($navItem);
  }
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility.menu-open nav ul > .isParent > a");
  if (!closest) return;

  const $navItem = closest.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if ($navItem.classList.contains(`open`)) {
    closeMenu($navItem);
  } else {
    openMenu($navItem);
    siblings.forEach(sibling => {
      closeMenu(sibling);
    })
  }
}, false);
