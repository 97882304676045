const supportsVideo = !!document.createElement("video").canPlayType;

document.addEventListener("DOMContentLoaded", function() {
  if (supportsVideo) {
    const video = document.querySelector("video");
    
    if (video) {
      const playPauseButton = document.querySelector(".video-button");
      
      // Hide the default controls
      video.controls = false;

      playPauseButton.addEventListener("click", () => {
        
        playPauseButton.classList.toggle("playing");
        if (playPauseButton.classList.contains("playing")) {
          video.play();
        } else {
          video.pause();
        }
      });

      video.addEventListener("ended", () => {
        playPauseButton.classList.remove("playing");
      });
    }
  }
});