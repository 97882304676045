import "../../../../node_modules/@lottiefiles/lottie-player/dist/lottie-player.js";
import { create } from "../../../../node_modules/@lottiefiles/lottie-interactivity/dist/lottie-interactivity.min.js";

window.addEventListener('load', (event) => {
  const animations = document.getElementsByTagName("lottie-player");

  for (i=0;i<animations.length;i++) {
    create({
      player: `#${animations.item(i).id}`,
      mode:"scroll",
      actions: [
        {
          visibility: [0.25, 0.6],
          type: "playOnce"
        }
      ]
    });
  }
});
